import { IonCol, IonContent, IonGrid, IonPage, IonRow } from '@ionic/react'
import * as react from 'react'

import Login from '../../components/login/login'

export class LoginPage extends react.Component {
  render() {
    return (
      <IonPage>
        <IonContent>
          <IonGrid>
            <IonRow>
              <IonCol
                offsetXl={'4'}
                sizeXl={'4'}
                offsetLg={'3'}
                sizeLg={'6'}
                offsetMd={'2'}
                sizeMd={'8'}
                offsetSm={'1'}
                sizeSm={'10'}
              >
                <Login allowGuest={true}></Login>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    )
  }
}
