import './Tab1.css'

import { IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react'

import AccountSettings from '../components/settings/account.settings'
import { provider } from '../provider'

const Tab1: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Tab 1</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Tab 1</IonTitle>
          </IonToolbar>
        </IonHeader>
        <AccountSettings />
        <IonButton
          onClick={async () => {
            console.log(await provider.service.marina.init())
          }}
        >
          click
        </IonButton>
      </IonContent>
    </IonPage>
  )
}

export default Tab1
