export const firebaseConfig = {
  apiKey: 'AIzaSyAKJ1CCyEZcyMTvcgeqdWODphL-4Uiapi0',
  authDomain: 'nextpier-staging.firebaseapp.com',
  projectId: 'nextpier-staging',
  storageBucket: 'nextpier-staging.appspot.com',
  messagingSenderId: '888837017557',
  appId: '1:888837017557:web:739a339b4e7fcac682775c',
  measurementId: 'G-CBRLJSZCW6',
}
export const domain = 'app.staging.nextpier.com'
