import { Http } from '@capacitor-community/http'
import { Directory, Encoding, Filesystem, StatResult } from '@capacitor/filesystem'
import { getDownloadURL, ref } from '@firebase/storage'

import { provider } from '../provider'

export class StorageService {
  private getCacheTimeKey(path: string): string {
    return `cache:ts:${path}`
  }

  async prepareCachedGs(
    path: string,
    timeout: number,
    encoding?: Encoding.UTF8,
    systemPath = Directory.Cache,
  ): Promise<string> {
    const gspath = `gs/${path}`

    const downloadFile = async () => {
      const url = await getDownloadURL(ref(provider.firebase.storage, path))
      const options = {
        url,
        filePath: gspath,
        fileDirectory: systemPath,
        method: 'GET',
      }
      const resp = await Http.downloadFile(options)
      if (resp.blob) {
        if (encoding) {
          await Filesystem.writeFile({
            directory: systemPath,
            path: gspath,
            data: await resp.blob.text(),
            recursive: true,
            encoding,
          })
        } else {
          const base64Blob: string = await new Promise((resolve, reject) => {
            var reader = new FileReader()
            reader.onloadend = function () {
              resolve(reader.result as string)
            }
            reader.readAsDataURL(resp.blob!)
          })
          const base64 = base64Blob.substr(base64Blob.indexOf(',') + 1)
          console.log(
            await Filesystem.writeFile({
              directory: systemPath,
              path: gspath,
              data: base64,
              recursive: true,
            }),
          )
        }

        console.log(
          await Filesystem.getUri({ path: gspath, directory: systemPath }),
        )
      }
    }

    let existingStat: StatResult | null

    try {
      existingStat = await Filesystem.stat({
        directory: systemPath,
        path: gspath,
      })
    } catch (e) {
      existingStat = null
    }

    console.log('STAT', existingStat)

    if (existingStat) {
      if (timeout && existingStat.mtime + timeout < Date.now()) {
        downloadFile().catch((e) => {
          console.log(e)
        })
        const result = await Filesystem.readFile({
          directory: systemPath,
          path: gspath,
          encoding,
        })
        return result.data
      }
    } else {
      console.log(await downloadFile())
    }
    const result = await Filesystem.readFile({
      directory: systemPath,
      path: gspath,
      //encoding,
    })
    return result.data
  }
}
