import { Memoize } from 'typescript-memoize'

import type { StorageService } from '../services/storage.service'
import type { MarinaService } from '../services/marina.service'

import type { AuthService } from '../services/auth.service'
class ServiceProvider {
  @Memoize()
  get auth() {
    const service: typeof AuthService =
      require('../services/auth.service').AuthService
    return new service()
  }

  @Memoize()
  get storage() {
    const service: typeof StorageService =
      require('../services/storage.service').StorageService
    return new service()
  }

  @Memoize()
  get marina() {
    const service: typeof MarinaService =
      require('../services/marina.service').MarinaService
    return new service()
  }
}

export const serviceProvider = new ServiceProvider()
