export const firebaseConfig = {
  apiKey: 'AIzaSyCWXhjTqdHTBvLfGA4k56hTTZmWH4386F4',
  authDomain: 'nextpier.firebaseapp.com',
  projectId: 'nextpier',
  storageBucket: 'nextpier.appspot.com',
  messagingSenderId: '61505403926',
  appId: '1:61505403926:web:31ed295ad38f5e2e334937',
  measurementId: 'G-EN280HL39Y',
}
export const domain = 'app.nextpier.com'
