import 'swiper/swiper-bundle.min.css'

import { getPlatforms, IonButton, IonCard, IonicSwiper, IonInput, IonItem, IonLabel } from '@ionic/react'
import { useEffect, useRef, useState } from 'react'
import SwiperCore from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { provider } from '../../provider'

// import '@ionic/react/css/ionic-swiper.css'
SwiperCore.use([IonicSwiper])
type LoginProps = {
  allowGuest?: boolean
}

const Login: React.FC<LoginProps> = ({ allowGuest = true }) => {
  const [input, setInput] = useState<string>('')
  const [name, setName] = useState<string>('')
  const [pwdCode, setPwdCode] = useState<string>('')
  const [step2, setStep2] = useState('skip')
  const [loading, setloading] = useState(false)
  const [swiperInstance, setSwiperInstance] = useState<any>()
  const [gsiScriptLoaded, setGsiScriptLoaded] = useState(false)
  const input1ref = useRef<any>(null)
  const input2ref = useRef<any>(null)
  const input3ref = useRef<any>(null)

  useEffect(() => {
    setTimeout(() => {
      input1ref.current.setFocus()
    }, 100)
  }, [])

  useEffect(() => {
    const handleGoogleSignIn = (res: CredentialResponse) => {
      if (!res.clientId || !res.credential) return

      provider.service.auth.loginWithGoogleToken(res.credential)
    }
    // load google sign in
    const useGoogleOneTapAndButton = getPlatforms().some((p) =>
      ['desktop', 'pwa', 'mobileweb'].includes(p),
    ) && (
      <>
        <script
          src="https://accounts.google.com/gsi/client"
          async
          defer
        ></script>
      </>
    )

    if (!useGoogleOneTapAndButton) {
      return
    }

    if (gsiScriptLoaded) return

    const initializeGsi = () => {
      // Typescript will complain about window.google
      // Add types to your `react-app-env.d.ts` or //@ts-ignore it.
      if (!window.google || gsiScriptLoaded) return

      setGsiScriptLoaded(true)
      window.google.accounts.id.initialize({
        client_id:
          '61505403926-vikqjvpmug4rk2bdri63ckhh5s07mh9t.apps.googleusercontent.com',
        callback: handleGoogleSignIn,
        login_uri: window.location.href,
      })
      window.google.accounts.id.prompt((data) => {
        console.log('prompt', data)
      })
    }

    const script = document.createElement('script')
    script.src = 'https://accounts.google.com/gsi/client'
    script.onload = initializeGsi
    script.async = true
    script.id = 'google-client-script'
    document.querySelector('body')?.appendChild(script)

    return () => {
      // Cleanup function that runs when component unmounts
      window.google?.accounts.id.cancel()
      document.getElementById('google-client-script')?.remove()
    }
  }, [gsiScriptLoaded])

  return (
    <Swiper
      slidesPerView={1}
      onSwiper={(swiper) => setSwiperInstance(swiper)}
      onSlideChange={() => console.log('slide change')}
      allowSlideNext={false}
      allowSlidePrev={false}
    >
      <SwiperSlide>
        <form
          onSubmit={async (e) => {
            e.preventDefault()
            setloading(true)
            const s2 = await provider.service.auth.checkNextStep(input)
            setStep2(s2)
            setloading(false)
            swiperInstance.allowSlideNext = true
            await swiperInstance.slideTo(1)
            setTimeout(() => {
              if (s2 === 'newUser') {
                input2ref.current.setFocus()
              } else {
                input3ref.current.setFocus()
              }
              swiperInstance.allowSlideNext = false
            }, 200)
          }}
        >
          <IonCard>
            <IonItem>
              <IonLabel position="stacked">Email</IonLabel>
              <IonInput
                value={input}
                placeholder="Email"
                type="email"
                disabled={loading}
                onSubmit={() => {
                  console.log('submit')
                }}
                onIonChange={(e) => {
                  console.log(e)
                  setInput(e.detail.value!)
                }}
                clearInput
                ref={(ref) => (input1ref.current = ref)}
              />
            </IonItem>
          </IonCard>

          <IonButton
            onClick={async () => {}}
            expand="block"
            shape="round"
            type="submit"
          >
            Continue
          </IonButton>
        </form>
        <IonButton
          fill="clear"
          expand="block"
          className="g_id_signin"
          data-type="standard"
          data-shape="pill"
          data-theme="outline"
          data-text="signin_with"
          data-size="large"
          data-logo_alignment="left"
          data-width="300"
        ></IonButton>
        {allowGuest && (
          <IonButton
            onClick={() => {
              provider.service.auth.loginGuest()
            }}
            expand="full"
            fill="clear"
          >
            continue as guest
          </IonButton>
        )}
      </SwiperSlide>
      <SwiperSlide>
        <form
          onSubmit={async (e) => {
            e.preventDefault()
            setloading(true)
            switch (step2) {
              case 'newUser':
                await provider.service.auth.createNewUserWithPassword(
                  input,
                  pwdCode,
                  name,
                )
                break
              case 'password':
                await provider.service.auth.loginWithEmailAndPassword(
                  input,
                  pwdCode,
                )
            }
            setloading(false)
          }}
        >
          <IonCard>
            <IonItem>
              <IonLabel position="stacked">Full Name</IonLabel>
              <IonInput
                value={name}
                placeholder="Name"
                disabled={loading}
                onIonChange={(e) => {
                  setName(e.detail.value!)
                }}
                ref={(ref) => (input2ref.current = ref)}
                clearInput
              />
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">Password</IonLabel>
              <IonInput
                value={pwdCode}
                type={'password'}
                placeholder="Password"
                disabled={loading}
                onIonChange={(e) => {
                  setPwdCode(e.detail.value!)
                }}
                ref={(ref) => (input3ref.current = ref)}
                clearInput
              />
            </IonItem>
          </IonCard>

          <IonButton type="submit" expand="block" shape="round">
            {step2 === 'newUser' ? 'Create Account' : 'Login'}
          </IonButton>
        </form>
        <IonButton
          onClick={async () => {
            setInput('')
            setPwdCode('')
            setStep2('skip')
            swiperInstance.allowSlidePrev = true
            swiperInstance.slideTo(0)
            setTimeout(() => {
              swiperInstance.allowSlidePrev = false
              input1ref.current.setFocus()
            }, 200)
          }}
          expand="block"
          shape="round"
          fill="outline"
        >
          cancel
        </IonButton>
      </SwiperSlide>
    </Swiper>
  )
}

export default Login
