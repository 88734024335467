import { alertController } from '@ionic/core'
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonItem, IonLabel, IonList } from '@ionic/react'
import { useRecoilValue } from 'recoil'

import { provider } from '../../provider'

const changeEmail = async () => {
  const alert = await alertController.create({
    inputs: [
      {
        type: 'email',
        name: 'email',
        label: 'Email',
        placeholder: 'Email',
      },
      {
        type: 'password',
        name: 'password',
        label: 'Password',
        placeholder: 'Password',
      },
    ],
    header: 'Change Email',
    message: 'Please enter a new email address.',
    buttons: [
      {
        text: 'Cancel',
        role: 'cancel',
      },
      {
        text: 'Okay',
        handler: (result) => {
          provider.service.auth.updateEmail(result.email, result.password)
        },
      },
    ],
  })
  const result = await alert.present()
  console.log(result)
}
const changePhone = () => {}
const changePassword = () => {}
const deleteAccount = () => {
  provider.service.auth.deleteAccount()
}

const AccountSettings: React.FC = () => {
  const user = useRecoilValue(provider.service.auth.currentUserState)
  if (!user) {
    return null
  }
  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>Account Settings</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <IonList>
          <IonItem button onClick={changeEmail}>
            <IonLabel>{user.email ?? 'add email address'}</IonLabel>
          </IonItem>

          <IonItem
            button
            onClick={() => provider.service.auth.sendEmailVerification()}
          >
            <IonLabel>'verify'</IonLabel>
          </IonItem>

          <IonItem button onClick={changePhone}>
            <IonLabel>{user.phoneNumber ?? 'add phone number'}</IonLabel>
          </IonItem>

          {!user.isAnonymous || true ? (
            <>
              <IonItem button onClick={changePassword}>
                <IonLabel>change password</IonLabel>
              </IonItem>

              <IonItem button onClick={() => provider.service.auth.logout()}>
                <IonLabel>logout</IonLabel>
              </IonItem>

              <IonItem button onClick={deleteAccount}>
                <IonLabel>delete account</IonLabel>
              </IonItem>
            </>
          ) : null}
        </IonList>
      </IonCardContent>
    </IonCard>
  )
}

export default AccountSettings
