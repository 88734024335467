import * as http from 'http'
import * as https from 'https'
import { Memoize } from 'typescript-memoize'

import { firebaseProvider } from './firebase.provider'
import * as prodConfig from './production.config'
import { serviceProvider } from './service.provider'
import * as stagingConfig from './staging.config'

import type {AxiosStatic} from 'axios'
class Provider {
  @Memoize()
  get service() {
    return serviceProvider
  }

  @Memoize()
  get firebase() {
    return firebaseProvider
  }

  @Memoize()
  get environment() {
    return require('./env.config').default
  }

  @Memoize()
  get config() {
    return this.environment === 'production' ? prodConfig : stagingConfig
  }

  @Memoize()
  get axios() {
    const Axios: AxiosStatic = require('axios').default
    return Axios.create({
      httpAgent: new http.Agent({ keepAlive: true }),
      httpsAgent: new https.Agent({ keepAlive: true }),
    })
  }
}

export const provider = new Provider()
