import {
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  GoogleAuthProvider,
  isSignInWithEmailLink,
  sendSignInLinkToEmail,
  signInAnonymously,
  signInWithCredential,
  signInWithEmailAndPassword,
  signInWithEmailLink,
  signOut,
  updateProfile,
  User,
  verifyBeforeUpdateEmail,
} from '@firebase/auth'
import { atom } from 'recoil'
import { Memoize } from 'typescript-memoize'

import { provider } from '../provider'

export class AuthService {
  constructor() {
    /*
    this.auth.onAuthStateChanged(async (user) => {
      if (user && !user.emailVerified) {
        await sendEmailVerification(user)
        console.log('Email send?')
      }
    })*/
    this.trySignInWithEmailLink()
  }

  private async trySignInWithEmailLink(email?: string | null) {
    email = email || window.localStorage.getItem('emailForSignIn')
    if (!email) return false
    if (!isSignInWithEmailLink(this.auth, window.location.href)) return false

    try {
      await signInWithEmailLink(this.auth, email, window.location.href)
      window.localStorage.removeItem('emailForSignIn')
      return true
    } catch (e) {
      console.error(e)
    }
    return false
  }

  @Memoize()
  get currentUserState() {
    return atom<null | User>({
      key: 'currentUser',
      default: null,
      effects_UNSTABLE: [
        ({ setSelf }) => {
          provider.firebase.auth.onAuthStateChanged((user) => {
            console.log('trigger')
            setSelf(user)
          })
        },
      ],
    })
  }

  async updateEmail(email: string, password: string) {
    const user = this.auth.currentUser
    if (!user) {
      return
    }

    if (user.isAnonymous) {
      console.log({ url: window.location.href, email })
      await sendSignInLinkToEmail(this.auth, email, {
        url: window.location.href,
        handleCodeInApp: true,
      })
    } else {
      await verifyBeforeUpdateEmail(this.auth.currentUser!, email)
    }
  }

  async checkNextStep(input: string) {
    if (/@/.test(input)) {
      if (await this.trySignInWithEmailLink(input)) return 'skip'

      const methods = await fetchSignInMethodsForEmail(this.auth, input)
      console.log({ methods })
      if (methods.length === 0) {
        return 'newUser'
      }
      if (methods.includes('password')) {
        return 'password'
      }
      await provider.firebase.functions.emailLogin({ email: input })
      window.localStorage.setItem('emailForSignIn', input)
      return 'email'
    }
    return 'skip'
  }

  async createNewUserWithPassword(
    email: string,
    password: string,
    name: string,
  ) {
    const result = await createUserWithEmailAndPassword(
      this.auth,
      email,
      password,
    )

    await updateProfile(result.user, { displayName: name })
    await this.sendEmailVerification()
  }

  async sendEmailVerification() {
    await provider.firebase.functions.emailVerify({})
  }

  async logout() {
    signOut(this.auth)
  }

  async deleteAccount() {
    await this.auth.currentUser?.delete()
  }

  async loginGuest() {
    await signInAnonymously(this.auth)
  }

  async loginWithEmailAndPassword(email: string, password: string) {
    signInWithEmailAndPassword(this.auth, email, password)
  }

  async loginWithGoogleToken(idToken: string) {
    const credential = GoogleAuthProvider.credential(idToken)
    await signInWithCredential(this.auth, credential)
  }

  private get auth() {
    return provider.firebase.auth
  }
}
