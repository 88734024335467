import { Encoding } from '@capacitor/filesystem'
import { Marina, marinaSchema } from '@nextpier/common'
import { atom } from 'recoil'
import { Memoize } from 'typescript-memoize'

import { provider } from '../provider'

export class MarinaService {
  private setMarinas: (val: Array<Marina>) => void = () => {}

  async init() {
    // call this.marinas to set the setMarinas value
    ;((_) => {})(this.marinas)

    const raw = await provider.service.storage.prepareCachedGs(
      'general/marinas.ndjson',
      86_400_000,
      Encoding.UTF8,
    )
    const lines = raw.split('\n')
    const rawMarinas = lines.filter(Boolean).map((line) => JSON.parse(line))
    this.setMarinas(
      rawMarinas.map((m) => {
        const marina = marinaSchema.validateSync(m)
        console.log(marina)
        return marina
      }),
    )
  }

  @Memoize()
  get marinas() {
    return atom<Array<Marina>>({
      key: 'marinas',
      default: [],
      effects_UNSTABLE: [
        ({ setSelf }) => {
          this.setMarinas = setSelf
        },
      ],
    })
  }
}
