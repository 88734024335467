import { initializeApp } from 'firebase/app'
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check'
import { getAuth } from 'firebase/auth'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { getStorage } from 'firebase/storage'
import { Memoize } from 'typescript-memoize'

import { provider } from '.'

class FirebaseProvider {
  @Memoize()
  get app() {
    const app = initializeApp(this.config)
    initializeAppCheck(app, {
      provider: new ReCaptchaV3Provider(
        '6Lc9yB0dAAAAADvMbQyCfRT4IcM18et6AwiH9Jx3',
      ),
      isTokenAutoRefreshEnabled: true,
    })
    return app
  }

  @Memoize()
  get config() {
    return provider.config.firebaseConfig
  }

  @Memoize()
  get auth() {
    return getAuth(this.app)
  }

  @Memoize()
  get functions() {
    const functions = getFunctions(this.app)
    return {
      emailVerify: httpsCallable<{}>(functions, 'emailVerify'),
      emailLogin: httpsCallable<{ email: string }>(functions, 'emailLogin'),
      emailPasswordReset: httpsCallable<{ email: string }>(
        functions,
        'emailPasswordReset',
      ),
    }
  }

  @Memoize()
  get storage() {
    return getStorage(this.app)
  }
}

export const firebaseProvider = new FirebaseProvider()
